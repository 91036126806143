import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Button, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "180px 0 180px 0",
	"background": "linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0,0,0,0.4) 100%),rgba(0, 0, 0, 0) url(https://amberglowsanctum.com/img/2.jpg) 50% 50% /cover repeat scroll padding-box",
	"md-padding": "100px 0 100px 0",
	"quarkly-title": "Content-12"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"padding": "0px 80px 0px 0px",
			"lg-width": "70%",
			"md-width": "100%",
			"md-padding": "0px 0 0px 0px",
			"md-margin": "0px 0px 50px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"font": "normal 500 20px/1.5 --fontFamily-sans",
			"color": "white",
			"children": "FEEL"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"font": "--headline2",
			"color": "white",
			"width": "50%",
			"children": "FEEL Kortlarında Bize Katılın!"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--light",
			"font": "--base",
			"width": "50%",
			"children": "En kaliteli toprak kortların oyununuzda yaratabileceği farkı deneyimleyin. İster eğlence, ister fitness, ister rekabet için oynuyor olun, FEEL Tennis Academy tam size göre. Bize katılın ve neden her seviyeden tenisçinin tercihi olduğumuzu keşfedin. Mükemmel oyununuz sizi bekliyor!"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"font": "normal 400 16px/1.5 --fontFamily-sans",
			"href": "/contacts",
			"type": "link",
			"text-decoration-line": "initial",
			"background": "--color-dark",
			"padding": "12px 28px 12px 28px"
		}
	}
};

const Cta = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			align-items="center"
			justify-content="center"
			md-flex-direction="column"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Text {...override("text2")} />
			<Button {...override("button")}>
				Bize Ulaşın
			</Button>
		</Box>
		{children}
	</Section>;
};

Object.assign(Cta, { ...Section,
	defaultProps,
	overrides
});
export default Cta;